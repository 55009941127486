
import AssistenteTecnologicoOption from '~/components/product/AssistenteTecnologicoOption.vue'
export default {
  name: 'AddToCart',
  props: ['product', 'sku', 'ctaText', 'ctaClass', 'isMinimal', 'isCta', 'isSticky'],
  data() {
    return {
      isAddingToCart: false,
      quantity: 1,
      selectedOption: false,
      optionalService: false,
      initialPosition: 0,
      scrollHandler: null
    }
  },
  components: { AssistenteTecnologicoOption },
  computed: {
    isLoading() {
      return this.$store.getters.isLoading
    },
    storeCountry() {
      return this.$store.getters.country
    },
    productData(){
      return this.sku ? this.$store.getters.product(this.sku) : this.product
    },
    // isShelfy(){
    //   return this.product?.name?.includes('Shelfy')
    // },
    // isEteria(){
    //   return this.product?.name?.includes('Eteria')
    // },
    isNatedeSmart(){
      return this.product?.name?.includes('Natede Smart')
    },
    // isNatedeBasic(){
    //   return this.product?.name?.includes('Natede Basic')
    // },
    hasOptionalService(){
      return this.product?.name?.includes('Natede') && this.storeCountry?.code === "IT"
    },
    // gpsdfkc(){
    //   let count = 0
    //   this.isShelfy ? count = this.gpsdfk(50,75) : 
    //   this.isEteria ? count = this.gpsdfk(40,65) : ''
    //   this.isNatedeSmart ? count = this.gpsdfk(35,55) : ''
    //   this.isNatedeBasic ? count = this.gpsdfk(42,70) : ''
    //   return count
    // }
  },
  watch: {
    product(){
      this.quantity = this.product.quantity
    }
  },
  mounted() {
    if (this.isSticky) {
      this.initialPosition = this.$refs.addToCartElement.getBoundingClientRect().bottom + window.scrollY;
      this.scrollHandler = this.debounce(this.checkButtonVisibility, 25)
      window.addEventListener('scroll', this.scrollHandler);
    }
  },
  beforeDestroy() {
    if (this.isSticky) {
      window.removeEventListener('scroll', this.scrollHandler)
    }
  },
  methods: {
    toggleOptionalService(service){
      this.optionalService = service
    },
    async addToCart(){
      if (!this.disabled) {
      this.isAddingToCart = true
      await this.$store.dispatch('addToCart', {...this.productData, quantity: this.quantity, sku_option: this.selectedOption})
      if (this.optionalService) {
        await this.$store.dispatch('addToCart', {...this.optionalService, quantity: this.quantity})
      }

      const eventAttributes = {
        "ecommerce": {
          "currency": this.productData.currency_code,
          "order_id": this.$store.getters.orderId,
          "value": this.productData.price * this.quantity,
          "items": [{
            "item_id": this.productData.bundle_code || this.productData.sku_code,
            "item_name": this.productData.name,
            "item_brand": "Vitesy",
            "price": this.productData.compare_at_price,
            "discount": this.productData.compare_at_price - this.productData.price,
            "value": this.productData.price,
            "quantity": this.quantity,
            "item_category": this.productData.category
          }]
        }
      }
      this.$gtm.push({ event: 'add_to_cart', ...eventAttributes })

      this.isAddingToCart = false
      this.$router.push({ path: this.localePath('/cart'), query: this.$route.query })
      }
    },
    debounce(func, wait, immediate) {
    let timeout;
    return function() {
      const context = this, args = arguments;
      const later = function() {
        timeout = null;
        if (!immediate) func.apply(context, args);
      };
      const callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      if (callNow) func.apply(context, args);
    };
  },
    checkButtonVisibility() {
    const buttonRect = this.$refs.addToCartElement.getBoundingClientRect();
    const viewportBottom = window.scrollY + window.innerHeight;
    const isButtonOutOfView = buttonRect.bottom < 0;
    const isPageScrolledPastButton = window.scrollY > this.initialPosition + 200;
    const totalPageHeight = document.body.scrollHeight;
    const isPageBottom = viewportBottom >= totalPageHeight - 800

    if ((isButtonOutOfView || isPageScrolledPastButton) && !isPageBottom) {
      this.$refs.addToCartElement.classList.add('sticky-add-to-cart');
    } else {
      this.$refs.addToCartElement.classList.remove('sticky-add-to-cart');
    }
  },
  // gpsdfk(t,n){t=isNaN(t)?0:t,n=isNaN(n)?1:n;const r=Math.floor(Date.now()/1e3),o=Math.floor(r/36e2);return(o%(n-t+1))+t}
  
  }
}
